<template>
  <div class="cart">

    <NavHeader />

    <div class="content_container content">
      <div class="tips">
        <dl>
          <dt>
            <img :src='$config.baseUrl+productInfo.goods_img_path'>
          </dt>
          <dd>
            <p> {{productInfo.goods_name}}</p>

            <p> 议价范围：{{productInfo.max_price}}-{{productInfo.min_price}}</p>
          </dd>

        </dl>
        <ul>
          <li>
            <span>
              您可以直接与商家客服进行在线议价：
              <a
                style="color: red"
                target="_blank"
                :href="'http://wpa.qq.com/msgrd?v=3&uin='+shop.kefu_qq+'&site=qq&menu=yes'"
              >

                点击进入QQ交谈

              </a>
            </span>

          </li>
          <li>也可以直接电话联系商家：{{shop.shop_tel}}</li>
          <li>或者可以在下面留下您的联系方式和需求，稍后商家会与您联系。</li>
        </ul>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        label-width="100px"
      >

        <el-form-item
          label="姓名"
          prop="name"
        >
          <el-input
            v-model="ruleForm.name"
            label=""
            placeholder="请输入姓名"
          />
        </el-form-item>
        <el-form-item
          label="电话"
          prop="phone"
        >
          <el-input
            v-model="ruleForm.phone"
            label=""
            placeholder="请输入电话"
          />
        </el-form-item>
        <el-form-item
          label="需求数量"
          prop="num"
        >
          <el-input
            v-model="ruleForm.num"
            label=""
            placeholder="请输入需求数量"
          />
        </el-form-item>

        <el-form-item
          label="其他说明"
          prop="content"
        >
          <el-input
            v-model="ruleForm.content"
            :rows="6"
            type="textarea"
            placeholder="请输入其他说明"
          />
        </el-form-item>
        <el-form-item label=" ">
          <p style=" margin-top: 20px; text-align:center">
            <el-button
              style="  width:200px;"
              block
              shape="circle"
              type="info"
              @click="summit('ruleForm')"
            >
              提交</el-button>
          </p>

        </el-form-item>
        <el-form-item label=" ">
          <p style="color:red"><i
              class="el-icon-warning themefont"
              style="font-size:16px"
            ></i> 提交议价留言后，将会有客服和您联系。 </p>

        </el-form-item>
      </el-form>
    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      ruleForm: {
        content: "",
        name: "",
        phone: "",
        num: ""
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "change" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "change" },
          {
            pattern: this.$validate.mobile,
            message: "请输入正确的电话",
            trigger: "change"
          }
        ],
        num: [
          { required: true, message: "请输入产品需求数量", trigger: "change" }
          // {
          //   pattern: this.$validate.number,
          //   message: "请输入数字",
          //   trigger: "change"
          // }
        ]
      },

      shop: {
        kefu_qq: "252738932"
      },
      productInfo: "",

      shopid: "",
      member_id: "",
      goodname: "",
      goodid: "",
      shopname: "",
      consult_content: "",
      member_name: ""
    };
  },
  computed: {},
  mounted() {
    this.getDetail();
  },
  methods: {
    // 获取产品信息
    getDetail() {
      this.$get("home/goods/" + this.$route.query.id, 1).then(res => {
        this.productInfo = res.result;
        console.log("  this.productInfo", this.productInfo);

        this.$get("seller/shop/" + res.result.goods_shop_id).then(res => {
          console.log("shop", res);
          this.shop = res;
        });
      });
    },

    summit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            zx_type: 1,
            goodid: this.productInfo.goods_id,
            goodname: this.productInfo.goods_name,
            shopid: this.productInfo.get_shop.shop_id,
            shopname: this.productInfo.get_shop.shop_name,
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
            num: this.ruleForm.num,
            member_id: this.$store.state.member.id,
            member_name: this.$store.state.member.name,
            content: this.ruleForm.content,
            goodlogo: this.productInfo.goods_img_path
          };
          this.$post("home/goodmessage", data).then(res => {
            this.$message.success("提交成功");

            this.$util.toDetail(
              this.productInfo.get_shop.shop_type,
              this.productInfo.goods_id
            );
          });
        } else {
          return false;
        }
      });
    }
  },
  components: {
    NavHeader
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.content {
  margin-top: 10px !important;
}
.tips {
  margin-bottom: 30px;

  @include space-between;
  dl {
    padding: 0;
    margin: 0;
    height: 150px;
    @include space-between;
    width: 400px;

    dt {
      width: 150px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    dd {
      margin-left: 10px;
      box-sizing: border-box;
      height: 150px;
      flex: 1;
      p:nth-child(1) {
        font-size: 14px;
        padding: 5px 0;
        font-weight: bold;
      }
    }
  }
  ul {
    background: #eee;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    margin-left: 30px;
    box-sizing: border-box;
    line-height: 40px;
    color: #666;

    box-sizing: border-box;
  }
}
// ::v-deep .el-textbox .txt-area textarea {
//   height: 2rem !important;
// }
// ::v-deep .el-row {
//   align-items: baseline;
//   display: flex;
//   margin-bottom:20px;
// }
.flex-content {
  line-height: 30px;
  text-align: center;
}
.my {
  height: 100%;
}
.content {
  background: #fff;
  padding: 20px;
  box-sizing: 0;
}
</style>
 